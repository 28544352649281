body {
  margin: 0;
}

.header {
  background-color: #232f3e;
  color: #fff;
  padding: 1em 1em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 2.5rem;
  z-index: 1500;
  display: flex;
  align-items: center;
}

.footer {
  background-color: #232f3e;
  color: #fff;
  padding: 1em 1em;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1rem;
  z-index: 1500;
  display: flex;
  align-items: center;
}

.text-orange {
  color: #99cbe4 !important;
}

.text-white {
  color: #ffffff !important;
}
.table-page-number {
  font-size: medium !important;
}
